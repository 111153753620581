import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

class LoadingCustom extends React.Component {
    
    render() {
        const { props } = this

        return (
            <div className="h-screen w-screen bg-gray-100 text-blue-500 flex flex-col items-center justify-center">
                <FontAwesomeIcon icon={faCircleNotch} spin size="6x" />
                <p className="text-gray-500 mt-4">{props.title}</p>
            </div>
        )
    }
}

LoadingCustom.propTypes = {
    title: PropTypes.string
}

export default LoadingCustom