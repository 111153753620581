import React, { Suspense } from 'react'
import { 
    BrowserRouter as Router,
    Switch,
    Route,
 } from "react-router-dom"
import { Provider } from "react-redux"
import { baseStores } from "helpers/Stores"
import MainLayout from "pages/layout"
import { LoadingCustom } from "components/index"

class MainRouter extends React.Component {
    render() {
        return (
            <Provider store={baseStores}>
                <Router>
                    <Suspense fallback={<LoadingCustom title="Preparation Progress. Please wait..."/>}>
                        <Switch>
                            <Route path="/" component={MainLayout} />
                        </Switch>
                    </Suspense>
                </Router>
            </Provider>
        );
    }
}

export default MainRouter;