import React from "react"
import PropTypes from "prop-types"

class CardSeminarCustom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }
    render() {
        const { props } = this

        return (
            <div 
                className="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3" >
                <div
                    className="flex flex-col pb-4 mb-6 transition-all duration-150 bg-white rounded-lg shadow-lg hover:shadow-2xl" >
                    <div 
                        className="banner"
                        style={{
                            height: "300px",
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'top',
                            backgroundSize: 'cover',
                            backgroundImage: props.data.banner ? `url(${process.env.REACT_APP_API_URL}/storage/images/seminar/${props.data.banner})` : `url(https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png)`
                        }}>
                    </div>
                    <div className="flex items-center justify-between px-4 py-2 overflow-hidden">
                        <span className="text-xs font-medium text-blue-600 uppercase">
                            { props.data.category }
                        </span>
                    </div>
                    <hr className="border-gray-300" />
                    <div 
                        className="flex flex-wrap items-center px-4 py-1 text-center mx-auto">
                        <a href="!#" className="hover:underline">
                            <h2 className="text-2xl font-bold tracking-normal text-gray-800">
                                { props.data.title }
                            </h2>
                        </a>
                    </div>
                    <hr className="border-gray-300" />
                    <p
                        className="flex flex-row flex-wrap flex-1 w-full px-4 py-2 overflow-hidden text-sm text-justify text-gray-700" >
                        <div dangerouslySetInnerHTML={{__html: `${props.data.description.substring(0, 800)}...`}}></div>
                    </p>
                    <hr className="border-gray-300" />
                    <section className="px-4 py-2 mt-2">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center flex-1">
                                <img
                                className="object-cover h-10 rounded-full"
                                src="https://thumbs.dreamstime.com/b/default-avatar-photo-placeholder-profile-icon-eps-file-easy-to-edit-default-avatar-photo-placeholder-profile-icon-124557887.jpg"
                                alt="Avatar"
                                />
                                <div className="flex flex-col mx-2">
                                <a href="!#" className="font-semibold text-gray-700 hover:underline">
                                    Kalikesia
                                </a>
                                <span className="mx-1 text-xs text-gray-600">{ props.time }</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

CardSeminarCustom.propTypes = {
    data: PropTypes.object,
}

export default CardSeminarCustom