const authAction = {
    SET_USER_IS_LOGGED  : "SET_USER_IS_LOGGED",
    SET_USER_DATA       : "SET_USER_DATA",
}

const authCreators = {
    _set_user_logged: (isLogged) => ({
      type: authAction.SET_USER_IS_LOGGED,
      payload: {isLogged},
    }),
    
    _set_user_data: (userData) => ({
      type: authAction.SET_USER_DATA,
      payload: {userData},
    }),
}

export {
    authAction,
    authCreators,
}
