import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from 'helpers/StorageServices'

const PublicRouteCustom = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? 
            <Component {...props} /> 
            : 
            <Redirect to={{ 
                pathname: '/' 
            }} 
        />}
    />
  )
}

export default PublicRouteCustom
