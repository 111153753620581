import Cookies from 'universal-cookie'

export const setCookies = () => {
    const cksApp = new Cookies()

    return cksApp
}

export const getToken = () => {
    return setCookies().get('_userToken') || null;
}

export const removeToken = () => {
    setCookies().remove('_userToken', {
        path: '/',
        sameSite: 'Lax'
    })
}
