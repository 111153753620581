import { authAction } from "action/auth/creators"

const initAuthState = {
    isLogged: false,
    userData: null,
}

const authRdc = (state = initAuthState, action) => {
    switch (action.type) {
        case authAction.SET_USER_IS_LOGGED:
            return {
                ...state,
                isLogged: action.payload.isLogged,
            }

        case authAction.SET_USER_DATA:
            return {
                ...state,
                userData: action.payload.userData,
            }

        default:
            return state
    }
}

export {
    authRdc,
}
