import React from "react"

class FooterCustom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }
    render() {
        return (
            <footer>
                <div className="container mx-auto text-center">
                    Kalikesia © 2021 build with care
                </div>
            </footer>
        )
    }
}

export default FooterCustom