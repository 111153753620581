import React from "react"
import "assets/scss/main.scss"
import Skeleton from "react-loading-skeleton"
import PropTypes from "prop-types"

class CardSkeleton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            count: 0,
         }
    }

    componentDidMount() {
        const { props } = this

        if (props.count) {
            this.setState({
                count: props.count
            })
        }
    }

    generate_cardSkeleton = () => {
        const { state } = this
        const CardData = []

        if (state.count > 0) {
            for (let index = 0; index < state.count; index++) {
                CardData.push(
                    <div className="max-w-xl" key={index}>
                        <div className="card-skeleton">
                            <Skeleton height={200} />
                            <Skeleton count={3} />
                            <div className="grid grid-cols-2 gap-4 flex items-center">
                                <Skeleton circle={true} height={50} width={50} />
                                <Skeleton />
                            </div>
                        </div>
                    </div>
                )
            }
        }

        return CardData
    }

    render() {
        return (
            <>
                {this.generate_cardSkeleton()}                
            </>
        )
    }
}

CardSkeleton.propTypes = {
    count: PropTypes.number
}

export default CardSkeleton