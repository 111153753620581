import reduxThunk from 'redux-thunk'
import { applyMiddleware, 
        combineReducers, 
        createStore } from 'redux'
import { authRdc } from 'action/auth/reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

let composedEnhancer
const middleware = [reduxThunk]

composedEnhancer = composeWithDevTools(applyMiddleware(...middleware))

const appReducers = combineReducers({
    authRdc
})

const baseStores = createStore(appReducers, composedEnhancer)

export {
    baseStores,
}
