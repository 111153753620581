import React, { lazy, Suspense } from "react"
import "assets/scss/main.scss"
import Navbar from "pages/layout/Navbar"
import { 
    Switch,
    Route,
 } from "react-router-dom"
import { URL } from "const/Url"
import { PublicRouteCustom } from "components/index"
import { FooterCustom, LoadingCustom } from "components/index"

const SigninPage = lazy(() => import("../auth/SignIn"))
const SignupPage = lazy(() => import("../auth/SignUp"))

const Homepage = lazy(() => import("../homepage"))
const Blogpage = lazy(() => import("../blog"))
const SeminarPage = lazy(() => import("../seminar"))

const NotFound = lazy(() => import("../layout/NotFound"))

class MainLayout extends React.Component {
    
    render() {
        const { props } = this

        return (
            <>
                <Navbar />
                
                <Suspense fallback={<LoadingCustom title="Loading Items..."/>}>
                    <Switch>
                        <PublicRouteCustom path={URL.HOMEPAGE} component={Homepage} exact/>
                        <PublicRouteCustom path={URL.BLOGPAGE} component={Blogpage} exact/>
                        <PublicRouteCustom path={URL.SEMINARPAGE} component={SeminarPage} exact/>
                        
                        <PublicRouteCustom path={URL.AUTH_SIGNIN} component={SigninPage} exact/>
                        <PublicRouteCustom path={URL.AUTH_SIGNUP} component={SignupPage} exact/>

                        <Route component={NotFound}/>
                    </Switch>
                </Suspense>

                <FooterCustom />
            </>
        )
    }
}

export default MainLayout