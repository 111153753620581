const URL = {
    HOMEPAGE    : '/',
    BLOGPAGE    : '/blog',
    SEMINARPAGE : '/seminar',
    KELAS       : '/kelas',

    AUTH_SIGNIN : '/auth/signin',
    AUTH_SIGNUP : '/auth/signup',
}

const API = {
    AUTH_SIGNIN : '/api/v1/login',
    AUTH_SIGNUP : '/api/v1/register',

    LIST_JOB    : '/api/v1/job-list',
}

export {
    API,
    URL,
}