import React from "react"
import PropTypes from "prop-types"

class HeaderCustom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }
    render() {
        const { props } = this

        return (
            <>
                <header 
                    className={`
                        min-w-full 
                        container 
                        font-sans 
                        font-bold 
                        flex 
                        flex-col 
                        justify-center 
                        items-center`}>
                            <h1 
                                className="text-5xl">
                                    { props.title }
                            </h1>
                            <p 
                                className="text-base">
                                    { props.description }
                            </p>

                            {props.searchForm}
                </header>
            </>
        )
    }
}

HeaderCustom.proptype = {
    title: PropTypes.string,
    description: PropTypes.string
}

export default HeaderCustom