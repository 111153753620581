import React from "react"
import 'assets/scss/main.scss'
import { connect } from "react-redux"
import LogoKalikesia from "assets/images/icons/logo_kalikesia_blue.png"
import { NavLink as RouterLink, withRouter } from "react-router-dom"
import { URL } from "const/Url"
import { removeToken } from "helpers/StorageServices"
import { authCreators } from "action/auth/creators"

class Navbar extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            isProfileMenuOpen: false
        }
    }

    click_buttonProfile = () => {
        const { state } = this

        this.setState({
            isProfileMenuOpen: !state.isProfileMenuOpen
        })
    }

    click_buttonSignOut = () => {
        const { props } = this

        props.dispatch(authCreators._set_user_logged(false))
        removeToken()

        props.history.push(URL.HOMEPAGE)
    }

    render() {
        const { props, state } = this

        return (
            <nav className="bg-white">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="navbar-link flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex-shrink-0 flex items-center">
                                <RouterLink 
                                    exact 
                                    to={URL.HOMEPAGE}>
                                        <img className="block lg:hidden h-8 w-auto" src={LogoKalikesia} alt="Workflow"></img>
                                        <img className="hidden lg:block h-8 w-auto" src={LogoKalikesia} alt="Workflow"></img>
                                </RouterLink>
                            </div>
                            <div className="hidden sm:block sm:ml-6">
                                <div className="flex space-x-4">
                                    <RouterLink 
                                        to={URL.SEMINARPAGE}
                                        activeClassName="selected">
                                        <p href="!#" className="text-gray-600 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-base font-medium">Seminar</p>
                                    </RouterLink>
                                    <a href="http://ukom.kalikesia.id/" className="text-gray-600 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-base font-medium">Ujian Kompetensi</a>
                                    {/* <a href="!#" className="text-gray-600 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-base font-medium">Kelas</a> */}
                                    <a href="https://blog.kalikesia.id" className="text-gray-600 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-base font-medium">Artikel Kesehatan</a>
                                </div>
                            </div>
                        </div>
                        {
                            props.isLogged ?

                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                    <span className="sr-only">View notifications</span>
                                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                    </svg>
                                </button>

                                <div className="ml-3 relative">
                                    <div>
                                        <button 
                                            className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" 
                                            id="user-menu" 
                                            aria-haspopup="true"
                                            onClick={this.click_buttonProfile}>
                                                <img className="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""></img>
                                                <span>{props.userData && props.userData.name}</span>
                                        </button>
                                    </div>

                                    <div 
                                        className={state.isProfileMenuOpen ? `origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-2 ring-black ring-opacity-5 md:mt-8` : `hidden`} 
                                        role="menu" 
                                        aria-orientation="vertical" 
                                        aria-labelledby="user-menu">
                                            <a href="!#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Your Profile</a>
                                            <a href="!#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Settings</a>
                                            <button 
                                                onClick={() => this.click_buttonSignOut()}
                                                className="w-full block px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100" >
                                                    Sign out
                                            </button>
                                    </div>
                                </div>
                            </div>
                            :
                            <button 
                                className="btn-auth flex text-lg font-semibold px-4 py-2"
                                onClick={() => props.history.push(URL.AUTH_SIGNIN)}>
                                <svg className="h-6 w-6 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                                </svg>
                                <span>Sign In / Sign Up</span>
                            </button>
                        }
                    </div>
                </div>

                <div className="hidden sm:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <a href="!#" className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Dashboard</a>
                        <a href="!#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Team</a>
                        <a href="!#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Projects</a>
                        <a href="!#" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">Calendar</a>
                    </div>
                </div>
            </nav>  
        )
    }
}

const mapStateToProps = state => ({
    isLogged: state.authRdc.isLogged,
    userData: state.authRdc.userData,
})

export default withRouter(connect(mapStateToProps)(Navbar))